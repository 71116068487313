html, body {
  margin: 0;

}
/* Estilo general para toda la app */
body {
  margin: 0 !important;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  overflow: hidden;
}
